import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';


export const ComingSoonCard = () => {


  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          Coming Soon!
        </Typography>
        <Typography color="textSecondary">
          This feature is currently under development and will be available soon.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ComingSoonCard;