import { useEffect, useState, useContext } from "react";
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { styled } from '@mui/material/styles';
import { AdminTabs } from "./AdminTabs";
import { Avatar, Box } from '@mui/material';
import { EntitlemeterContext } from "../App";

export const Users = () => {

    const { sessionObj } = useContext(EntitlemeterContext)
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/admin_get_users?sessionId=${sessionObj?.id}`)
            .then((data) => data.json())
            .then((data) => {
                setTableData(data)
                console.log("in useEffect Data = " + data)
            })
    }, [sessionObj.id])

    const columns = [
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'givenName', headerName: 'First Name', width: 120, align: 'left' },
        { field: 'familyName', headerName: 'Last Name', width: 150, align: 'left' },
        {
            field: 'picture',
            headerName: 'Picture',
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Avatar src={params.value} />
                    </>
                );
            }
        },
        { field: 'roles', headerName: 'Roles', width: 100 },
        {
            field: 'lastLoginDate',
            headerName: 'Last Login Date (Local)',
            width: 250,
            renderCell: (params) => {

                let dt = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(params.value)

                return (
                    <>
                        {dt}
                    </>
                );
            }
        }
    ]

    function customCheckbox(theme) {
        return {
            '& .MuiCheckbox-root svg': {
                width: 16,
                height: 16,
                backgroundColor: 'transparent',
                border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
                    }`,
                borderRadius: 2,
            },
            '& .MuiCheckbox-root svg path': {
                display: 'none',
            },
            '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
                backgroundColor: '#1890ff',
                borderColor: '#1890ff',
            },
            '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
                position: 'absolute',
                display: 'table',
                border: '2px solid #fff',
                borderTop: 0,
                borderLeft: 0,
                transform: 'rotate(45deg) translate(-50%,-50%)',
                opacity: 1,
                transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
                content: '""',
                top: '50%',
                left: '39%',
                width: 5.71428571,
                height: 9.14285714,
            },
            '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
                width: 8,
                height: 8,
                backgroundColor: '#1890ff',
                transform: 'none',
                top: '39%',
                border: 0,
            },
        };
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 0,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        ...customCheckbox(theme),
    }));

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={page + 1}
                count={pageCount}
                // @ts-expect-error
                renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
        );
    }


    return (
        <div>
            {(sessionObj.roles === "admin")
                ? <><AdminTabs />
                    <Box sx={{ height: 700, width: '100%', align: 'center' }}>
                        <StyledDataGrid
                            checkbosSelection
                            pageSize={20}
                            getRowId={(row) => row._id}
                            rows={tableData}
                            columns={columns}
                            rowsPerPage={[50]}
                            components={{
                                Pagination: CustomPagination,
                            }}
                        />
                    </Box>
                </>
                : <>Not Authorized</>
            }
        </div>
    )
};
