import { Box } from "@mui/material";
export const Privacy = () => {
    return (
        <Box display="flex" justifyContent="center" minHeight="100vh">
            <Box maxWidth="80%">
                <h2>Privacy Policy</h2>
                <p>
                    This Privacy Policy (the “Policy”) explains how Entitlemeter
                    LLC (the “Company”) collects, uses, and discloses
                    information obtained from its users of its website(s) and
                    services (the “Services”). All capitalized terms will have
                    the meaning given to them in this Policy.
                </p>
                <h2>Collection of Personal Data</h2>
                <p>
                    The Company collects personal data from its users when they
                    register for an account or use the Services. This data may
                    include email address, name, phone number, IP address,
                    browser type, etc. The Company may also collect non-personal
                    data from users such as web pages visited, time spent on the
                    Services, etc.
                </p>
                <h2>Use of Information</h2>
                <p>
                    The Company uses the collected information to improve its
                    Services, provide customer support, detect and prevent fraud
                    and abuse, and to personalize the user experience.
                </p>
                <h2>Sharing of Information</h2>
                <p>
                    The Company does not share or disclose personal data with
                    any third parties for marketing purposes. We may share your
                    personal data with third parties to provide Services or
                    process payments. We may also share your information with
                    third parties if we believe it is necessary to comply with
                    the law or to protect the rights, property, or safety of the
                    Company, our users, or others.
                </p>
                <h2>Security</h2>
                <p>
                    The Company takes reasonable steps to protect personal data
                    from loss, misuse, and unauthorized access, disclosure,
                    alteration, or destruction.
                </p>
                <h2>Changes to the Policy</h2>
                <p>
                    The Company reserves the right to modify this Policy at any
                    time. Any changes to this Policy will be posted on the
                    Company’s website.
                </p>
                <br />
                <p>
                    Contact Us If you have any questions regarding this Policy,
                    please contact us at <a href="mailto:hello@entitlemeter.com">hello@entitlemeter.com</a>.
                </p>
            </Box>
        </Box>
    );
};
