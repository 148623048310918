import { Box, Container, Grid, Link } from "@mui/material";
import CookieConsent from "react-cookie-consent";
import { useTheme } from "@mui/material/styles";

export const Footer = () => {
    const theme = useTheme();
    return (
        <footer>
            <Box
                px={{ xs: 3, sm: 10 }}
                py={{ xs: 5, sm: 10 }}
                sx={{ flexGrow: 1, backgroundColor: theme.palette.mode === "dark" ? "#000" : "#DDD"}} 
                borderTop={2}
            >
                <Container maxWidth={"lg"}>
                    <Grid container spacing={10}>
                        <Grid item xs={12} sm={3}>
                            <Box borderBottom={1}>Legal</Box>
                            <Box>
                                <Link
                                    href={"/privacy"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Privacy Policy
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={"/terms"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Terms of Use
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={"/"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Copyright and Trademark
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box borderBottom={1}>Account</Box>
                            <Box>
                                <Link
                                    href={"/"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Standard
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={"/"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Pro
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={"/"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Enterprise
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box borderBottom={1}>Help</Box>
                            <Box>
                                <Link
                                    href={"/"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    FAQ
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={"/"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    About
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={"/"}
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Contact
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box borderBottom={1}>Social</Box>
                            <Box>
                                <Link
                                    href={"https://twitter.com/entitlemeter"}
                                    target="_blank"
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Twitter
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={
                                        "https://www.linkedin.com/company/entitlemeter"
                                    }
                                    target="_blank"
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    LinkedIn
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={"https://medium.com/@entitlemeter"}
                                    target="_blank"
                                    underline={"hover"}
                                    color={"inherit"}
                                >
                                    Medium
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box
                        textAlign={"center"}
                        pt={{ xs: 5, sm: 10 }}
                        pb={{ xs: 5, sm: 0 }}
                    >
                        Copyright &copy; {new Date().getFullYear()} Entitlemeter
                        LLC, all rights reserved
                    </Box>
                </Container>
            </Box>
            <CookieConsent
                debug={false} // set this true to test
                location="bottom"
                buttonText="I Agree"
                cookieName="Entitlemeter_Cookie_Consent"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                enableDeclineButton
                onAccept={(acceptedByScrolling) => {
                    if (acceptedByScrolling) {
                        // triggered if user scrolls past threshold
                        alert("Accept was triggered by user scrolling");
                    } else {
                        alert(
                            "Accept was triggered by clicking the Accept button"
                        );
                    }
                }}
            >
                <span style={{ fontSize: "30px" }}>
                    This website uses cookies
                </span>
                <br />
                We use cookies to personalise content and ads, to provide social
                media features and to analyze our traffic. Please click the "I
                Agree" to continue. If you decline, you will not be able to use
                this site.
            </CookieConsent>
        </footer>
    );
};
