import { useContext, useEffect, useState } from "react";
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { ErrorBoundary } from "react-error-boundary";
import { EntitlemeterContext } from "./App";
import { StyledToggleButton } from "./components/StyledToggleButton";
import Plot from "react-plotly.js";
import moment from "moment";
import Axios from "axios";

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div role="alert">
            <p>Something went wrong: Tweet could not be found.</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
}

export const ContextGraph = (context) => {
    const { sessionObj } = useContext(EntitlemeterContext);
    const theme = useTheme();

    let contextTitle = "";
    switch (context.context) {
        case "entitle":
            contextTitle = "Entitled";
            break;
        case "bully":
            contextTitle = "Bullying";
            break;
        case "curse":
            contextTitle = "Cursing";
            break;
        default:
            contextTitle = "Unknown";
    }

    // Manage State
    const [dialogColor, setDialogColor] = useState("green"); // Title of the dialog with Entitlemeter Score
    const [dialogTitle, setDialogTitle] = useState(""); // Title of the dialog with Entitlemeter Score
    const [id, setId] = useState(""); // show this tweetId in the dialog
    const [showTweetDialog, setShowTweetDialog] = useState(false); // set true with tweet dialog is shown
    const [tweets, setTweets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [vote, setVote] = useState({
        tweetId: "",
        context: "",
        value: null,
        _id: null,
    });

    useEffect(() => {
        Axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/most_by_context?context=${context.context}`,
            {
                headers: {
                    "content-type": "application/json",
                },
            }
        )
            .then((res) => {
                //console.log(res.data);
                res.data = res.data.map((item) => {
                    return { ...item, date: new Date(Date.parse(item.date)) };
                });
                res.data = res.data.map((item) => {
                    const text = [
                        "<b>",
                        `By: @${item.twitterId}`,
                        `Date: ${moment(item.date).format("M/D/YYYY h:mm A")}`,
                        `Entitlemeter Score: ${item.score}`,
                        "CLICK TO SEE TWEET",
                        "</b>",
                    ].join("<br />");
                    return { ...item, text: text };
                });
                //console.log(res.data);
                setTweets(res.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [isLoading, context]);

    // Tweet Dialog Uses This to Close
    const handleDialogClose = () => {
        setShowTweetDialog(false);
    };

    return (
        <div
            style={{
                position: "relative",
            }}
        >
            <Backdrop
                sx={{
                    position: "absolute",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
                Loading...
            </Backdrop>
            <Typography variant="h5" align="center">
                Most {contextTitle} Tweets (24 Hours)
            </Typography>

            <Plot
                useResizeHandler
                theme="plotly_dark"
                template="plotly_dark"
                style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}
                data={[
                    {
                        x: tweets.map((tweet) => tweet.date),
                        y: tweets.map((tweet) => tweet.score),
                        text: tweets.map((tweet) => tweet.text),
                        id: tweets.map((tweet) => tweet.id),
                        type: "scatter",
                        mode: "markers",
                        hoverinfo: "text",
                        colorbar: {
                            tickfont: {
                                color: theme.palette.text.primary,
                            },
                        },
                        marker: {
                            size: tweets.map((tweet) => tweet.size),
                            opacity: 0.75,
                            color: tweets.map((tweet) => tweet.color),
                        },
                    },
                ]}
                layout={{
                    // TODO: we need to split the tweet data into 3 traces (low, medium, high)
                    // to get a useful legend
                    paper_bgcolor: theme.palette.background.paper,
                    plot_bgcolor: theme.palette.background.paper,
                    showlegend: false,
                    legend: {
                        x: 1,
                        xanchor: "right",
                        y: 1,
                        font: {
                            color: theme.palette.text.primary,
                        },
                    },
                    autosize: true,
                    title: "Click bubble to see tweet",
                    titlefont: {
                        family: "Arial, sans-serif",
                        size: 18,
                        color: theme.palette.text.primary,
                    },
                    margin: { autoexpand: true, l: 0, r: 0, b: 0, t: 30 },
                    xaxis: {
                        type: "date",
                        color: theme.palette.text.primary,
                        automargin: true,
                    },
                    yaxis: {
                        color: theme.palette.text.primary,
                        range: [
                            tweets.length > 0
                                ? tweets.reduce(
                                    (min, obj) =>
                                        obj.score < min ? obj.score : min,
                                    tweets[0].score
                                ) - 5
                                : 0,
                            tweets.length > 0
                                ? tweets.reduce(
                                    (max, obj) =>
                                        obj.score > max ? obj.score : max,
                                    tweets[0].score
                                ) + 5
                                : 100,
                        ],
                        title: "Entitlemeter Score",
                        titlefont: {
                            family: "Arial, sans-serif",
                            size: 18,
                            color: theme.palette.text.primary,
                        },
                        automargin: true,
                    },
                    scene: {
                        xaxis: {
                            color: theme.palette.text.primary,
                        },
                        yaxis: {
                            color: theme.palette.text.primary,
                        },
                        zaxis: {
                            color: theme.palette.text.primary,
                        },
                    },
                }}
                onClick={(e) => {
                    setVote({
                        tweetId: "",
                        context: "",
                        value: null,
                        _id: null,
                    });
                    setDialogTitle("Entitlemeter Score = " + e.points[0].y);
                    setDialogColor(
                        e.points[0].data.marker.color[e.points[0].pointIndex]
                    );
                    let tweetId =
                        e.points[0].data.id[e.points[0].pointIndex].toString();

                    console.log(
                        e.points[0].data.text[e.points[0].pointIndex].toString()
                    );
                    setId(tweetId);
                    setShowTweetDialog(true);
                    if (sessionObj?.roles === "admin") {
                        fetch(
                            `${process.env.REACT_APP_API_BASE_URL}/vote?tweet_id=${tweetId}&context=${context.context}&session_id=${sessionObj.id}`
                        )
                            .then((data) => data.json())
                            .then((data) => {
                                if (data?._id) {
                                    setVote(data);
                                }
                            });
                    }
                }}
            />

            {/* Tweet Viewer Popup Dialog */}
            <Dialog onClose={handleDialogClose} open={showTweetDialog}>
                <DialogTitle sx={{ backgroundColor: dialogColor }}>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: "auto",
                        width: "fit-content",
                    }}
                >
                    <ErrorBoundary
                        onError={(error) => {
                            alert("error");
                            console.error(error);
                        }}
                        FallbackComponent={ErrorFallback}
                    >
                        <TwitterTweetEmbed tweetId={id}></TwitterTweetEmbed>
                    </ErrorBoundary>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    {sessionObj?.roles === "admin" && (
                        <>
                            <ToggleButtonGroup
                                value={vote.value}
                                exclusive
                                onChange={(_, val) => {
                                    console.log(val, vote.value, vote._id);
                                    if (
                                        val !== vote.value &&
                                        val === null &&
                                        vote._id
                                    ) {
                                        console.log(val, "delete");
                                        fetch(
                                            `${process.env.REACT_APP_API_BASE_URL}/vote/${vote._id}?session_id=${sessionObj.id}`,
                                            {
                                                method: "DELETE",
                                            }
                                        ).then(() => {
                                            setVote({
                                                tweetId: vote.tweetId,
                                                context: vote.context,
                                                value: null,
                                                _id: null,
                                            });
                                        });
                                    } else {
                                        fetch(
                                            `${process.env.REACT_APP_API_BASE_URL}/vote`,
                                            {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type":
                                                        "application/json",
                                                },
                                                body: JSON.stringify({
                                                    tweet_id: id,
                                                    context: context.context,
                                                    session_id: sessionObj.id,
                                                    value: val,
                                                }),
                                            }
                                        )
                                            .then((data) => data.json())
                                            .then((data) => {
                                                data._id = data.id;
                                                if (!data._id && vote._id) {
                                                    data._id = vote._id;
                                                }
                                                setVote(data);
                                            });
                                    }
                                }}
                            >
                                <StyledToggleButton
                                    value={true}
                                    selectedColor="#00abc0"
                                >
                                    {contextTitle}
                                </StyledToggleButton>
                                <StyledToggleButton
                                    value={false}
                                    selectedColor="#00abc0"
                                >
                                    NOT {contextTitle}
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};
