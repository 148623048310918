import { useContext, useState, useEffect } from "react";
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    ToggleButtonGroup,
    // ToggleButton,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { EntitlemeterContext } from "./App";
import { useSearchParams } from "react-router-dom";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { ErrorBoundary } from "react-error-boundary";
import { StyledToggleButton } from "./components/StyledToggleButton";
import Axios from "axios";
import moment from "moment";
import React from "react";
import Plot from "react-plotly.js";

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div role="alert">
            <p>Something went wrong: Tweet could not be found.</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
}

export const Results = () => {
    const { sessionObj } = useContext(EntitlemeterContext);
    const theme = useTheme();

    // Manage State
    const [dialogColor, setDialogColor] = useState("green"); // Title of the dialog with Entitlemeter Score
    const [dialogTitle, setDialogTitle] = useState(""); // Title of the dialog with Entitlemeter Score
    const [id, setId] = useState(""); // show this tweetId in the dialog
    const [showTweetDialog, setShowTweetDialog] = useState(false); // set true with tweet dialog is shown
    const [tweets, setTweets] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [vote, setVote] = useState({
        tweetId: "",
        context: "",
        value: null,
        _id: null,
    });

    // Read params passed from header as ?twitterId=xxxxxx
    let [searchParams] = useSearchParams();

    // Build query params
    const twitterId = searchParams.get("twitterId");
    const email = sessionObj ? sessionObj.email : "";
    const sessionId = sessionObj ? sessionObj.id : "0";

    // Build the Search Payload
    let search = JSON.stringify({
        email: email,
        twitterId: twitterId,
        sessionId: sessionId,
    });

    useEffect(() => {
        //console.log("search TwitterId:", search);
        //console.log("isLoading:", isLoading);
        // Get tweets from search api for given twitterId
        Axios.post(`${process.env.REACT_APP_API_BASE_URL}/search?`, search, {
            headers: {
                "content-type": "application/json",
            },
        })
            .then((res) => {
                res.data.date = res.data.date.map(function callback(element) {
                    return new Date(Date.parse(element));
                });
                res.data.text = res.data.date.map(function callback(
                    element,
                    idx
                ) {
                    return [
                        "<b>",
                        `Date: ${moment(element).format("M/D/YYYY h:mm A")}`,
                        `Entitlemeter Score: ${res.data.score[idx]}`,
                        "CLICK TO SEE TWEET",
                        "</b>",
                    ].join("<br />");
                });
                setTweets(res.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [search, isLoading]);

    /* The Tweet Viewer uses this to close */
    const handleDialogClose = () => {
        setShowTweetDialog(false);
    };

    return (
        <div
            style={{
                position: "relative",
            }}
        >
            <Backdrop
                sx={{
                    position: "absolute",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
            >
                <CircularProgress color="inherit" text="foo" />
                Loading...
            </Backdrop>
            <Typography variant="h5" align="center">
                Entitlemeter&#8482; Results for {twitterId}
            </Typography>

            <Plot
                useResizeHandler
                style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}
                data={[
                    {
                        x: tweets.date,
                        y: tweets.score,
                        // TODO: generate hover markeup client side instead of server side
                        text: tweets.text,
                        id: tweets.id,
                        type: "scatter",
                        mode: "markers",
                        hoverinfo: "text",
                        marker: {
                            size: tweets.size,
                            opacity: 0.75,
                            color: tweets.color,
                        },
                        colorbar: {
                            tickfont: {
                                color: theme.palette.text.primary,
                            },
                        },
                    },
                ]}
                layout={{
                    // TODO: we need to split the tweet data into 3 traces (low, medium, high)
                    // to get a useful legend
                    paper_bgcolor: theme.palette.background.paper,
                    plot_bgcolor: theme.palette.background.paper,
                    showlegend: false,
                    legend: {
                        x: 1,
                        xanchor: "right",
                        y: 1,
                        font: {
                            color: theme.palette.text.primary,
                        },
                    },
                    autosize: true,
                    title: "Click bubble to see tweet",
                    titlefont: {
                        family: "Arial, sans-serif",
                        size: 18,
                        color: theme.palette.text.primary,
                    },
                    margin: { autoexpand: true, l: 0, r: 0, b: 0, t: 30 },
                    xaxis: {
                        type: "date",
                        //nticks: 15,
                        //tickformat: '%d %B (%a)\n %Y',
                        //showticklabels: true,
                        //tickangle: 45,
                        automargin: true,
                        color: theme.palette.text.primary,
                    },
                    yaxis: {
                        color: theme.palette.text.primary,
                        range: [0, 110],
                        title: "Entitlemeter Score",
                        titlefont: {
                            family: "Arial, sans-serif",
                            size: 18,
                            color: theme.palette.text.primary,
                        },
                        automargin: true,
                    },
                    scene: {
                        xaxis: {
                            color: theme.palette.text.primary,
                        },
                        yaxis: {
                            color: theme.palette.text.primary,
                        },
                        zaxis: {
                            color: theme.palette.text.primary,
                        },
                    },
                }}
                onClick={(e) => {
                    setDialogTitle("Entitlemeter Score = " + e.points[0].y);
                    console.log(
                        "color=" +
                            e.points[0].data.marker.color[
                                e.points[0].pointIndex
                            ]
                    );
                    setDialogColor(
                        e.points[0].data.marker.color[e.points[0].pointIndex]
                    );
                    let tweetId =
                        e.points[0].data.id[e.points[0].pointIndex].toString();
                    console.log(
                        "tweetId = " + tweetId + " typeof=" + typeof tweetId
                    );
                    setId(tweetId);
                    for (let i = 0; i < e.points.length; i++) {
                        console.log(e.points[i]);
                    }
                    setShowTweetDialog(true);
                }}
            />

            <Dialog onClose={handleDialogClose} open={showTweetDialog}>
                <DialogTitle sx={{ backgroundColor: dialogColor }}>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: "auto",
                        width: "fit-content",
                    }}
                >
                    <ErrorBoundary
                        onError={(error) => {
                            alert("error");
                            console.error(error);
                        }}
                        FallbackComponent={ErrorFallback}
                    >
                        <TwitterTweetEmbed
                            tweetId={id}
                            onLoad={(e) => console.log(e)}
                        ></TwitterTweetEmbed>
                    </ErrorBoundary>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    {sessionObj?.roles === "admin" && (
                        <>
                            <ToggleButtonGroup
                                value={vote.value}
                                exclusive
                                onChange={(_, val) => {
                                    console.log(val, vote.value, vote._id);
                                    if (
                                        val !== vote.value &&
                                        val === null &&
                                        vote._id
                                    ) {
                                        console.log(val, "delete");
                                        fetch(
                                            `${process.env.REACT_APP_API_BASE_URL}/vote/${vote._id}?session_id=${sessionObj?.id}`,
                                            {
                                                method: "DELETE",
                                            }
                                        ).then(() => {
                                            setVote({
                                                tweetId: vote.tweetId,
                                                context: vote.context,
                                                value: null,
                                                _id: null,
                                            });
                                        });
                                    } else {
                                        fetch(
                                            `${process.env.REACT_APP_API_BASE_URL}/vote`,
                                            {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type":
                                                        "application/json",
                                                },
                                                body: JSON.stringify({
                                                    tweet_id: id,
                                                    context: "entitle",
                                                    session_id: sessionObj.id,
                                                    value: val,
                                                }),
                                            }
                                        )
                                            .then((data) => data.json())
                                            .then((data) => {
                                                data._id = data.id;
                                                if (!data._id && vote._id) {
                                                    data._id = vote._id;
                                                }
                                                setVote(data);
                                            });
                                    }
                                }}
                            >
                                <StyledToggleButton
                                    value={true}
                                    selectedColor="#00abc0"
                                >
                                    ENTITLED
                                </StyledToggleButton>
                                <StyledToggleButton
                                    value={false}
                                    selectedColor="#00abc0"
                                >
                                    NOT ENTITLED
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};
