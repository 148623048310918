import {useContext} from "react";
import {Button, Paper} from '@mui/material';
import {EntitlemeterContext} from "../App";

export const Profile = () => {

    const {sessionObj} = useContext(EntitlemeterContext)

    return (
        <Paper>
            <h3>Welcome</h3>
            { (sessionObj)
                ? <>
                    <h3>{sessionObj.givenName+ ' ' + sessionObj.familyName}</h3>
                    <h3>{sessionObj.id}</h3>
                    <h3>{sessionObj.email}</h3>
                    <h3>{sessionObj.picture}</h3>
                    <h3>{sessionObj.roles}</h3>
                    <h3>{sessionObj.loginDate}</h3>
                    <h3>{sessionObj.colorMode}</h3>
                    <Button>Night Mode</Button>
                </>
                : <h3>Unknown user</h3>
            }

        </Paper>
    )
};