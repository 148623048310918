import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';

export const AdminTabs = () => {

    // Manage State
    const [value, setValue] = useState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
 
        <Box sx={{ width: '100%', backgroundColor: "#ccc" }}>
            <Tabs
                sx={{
                    "& a" : {textColor:"#000"},
                    "& a:hover": {backgroundColor: "#333", color:"#ccc"},
                    "& a:focus": {backgroundColor: "#333", color:"#ccc", textColor:"#000"},
                    "& a:active": {backgroundColor: "#333", color:"#ccc", textColor:"#000"},
                    "& a:Mui-selected": {backgroundColor: "#333", color:"#ccc", textColor:"#000"}
                }}
                value={value} onChange={handleChange} aria-label="nav tabs">
                <Tab component={Link} label="Sessions" to="/sessions"  />
                <Tab component={Link} label="Users" to="/users" />
                <Tab component={Link} label="Searches" to="/searches" />
                <Tab component={Link} label="Servers" to="/servers" />
                <Tab component={Link} label="AI" to="/ai" />
                <Tab component={Link} label="Votes" to="/votes" />
            </Tabs>
        </Box>
    );
}