import { useContext, useEffect, useState } from "react";
import { EntitlemeterContext } from "../App";
import { AdminTabs } from "./AdminTabs";
import { Box } from '@mui/material';

export const Servers = () => {

    const {sessionObj} = useContext(EntitlemeterContext)
    const [serverData, setServerData] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/admin_get_servers?sessionId=${sessionObj?.id}`)
            .then((data) => data.json())
            .then((data) => {
                setServerData(data)
                console.log("in useEffect Data = " + data)
            })
    }, [sessionObj.id])


    return(
        <div>
            {(sessionObj?.roles==="admin")
                ?  <><AdminTabs />

                    <Box sx={{ height: 700, width: '100%', align:'center' }}>
                        <div>Unscored Tweet Queue Length: {Number(serverData.unscored_tweets_length).toLocaleString("en-US")}</div>
                        <div>Scored Tweet Queue Length: {Number(serverData.scored_tweets_length).toLocaleString("en-US")}</div>
                        <div>Interactive Unscored Tweet Queue Length: {Number(serverData.interactive_unscored_length).toLocaleString("en-US")}</div>
                    </Box>
                </>
                : <>Not Authorized</>
            }
        </div>
    )
};
