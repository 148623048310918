import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
    Avatar,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Grid,
    Link as LinkButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { SocialIcon } from "react-social-icons";
import { Link as RouterLink } from "react-router-dom";

import { EntitlemeterContext } from "./App";
import { Axios } from "axios";

export const Header = (colorMode) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { sessionObj, setSessionObj } = useContext(EntitlemeterContext);
    const [twitterId, setTwitterId] = useState("");

    useEffect(() => {
        const handleTwitterUserChange = setTimeout(() => {
            if (
                !twitterId ||
                twitterId.length < 3 ||
                twitterId === "undefined"
            ) {
                return false;
            }
            navigate({ pathname: "/", search: "?twitterId=" + twitterId });
        }, 800);

        return () => clearTimeout(handleTwitterUserChange);
    }, [twitterId, navigate]);

    const logout = () => {
        if (sessionObj) {
            let session_end = JSON.stringify({
                id: sessionObj.id,
                email: sessionObj.email,
                givenName: sessionObj.givenName,
                familyName: sessionObj.familyName,
                picture: sessionObj.picture,
                colorMode: sessionObj.colorMode,
                isAdmin: false,
                loginDate: sessionObj.loginDate,
            });

            console.log("session_end:", session_end);

            Axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/session_end`,
                session_end,
                {
                    headers: {
                        "content-type": "application/json",
                    },
                }
            ).then(
                (res) => {
                    console.log("res", res);
                    console.log(process.env.NODE_ENV);
                    setSessionObj(null);
                    localStorage.setItem(`em-session-${process.env.NODE_ENV}`, undefined)
                },
                (error) => {
                    console.log("Error" + error);
                }
            );
        }
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                backgroundColor:
                    theme.palette.mode === "dark" ? "#000" : "#DDD",
            }}
            borderBottom={2}
        >
            <Grid container direction="row">
                <Grid
                    container
                    item
                    xs={3}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    sx={{ pl: 1 }}
                >
                    <Typography
                        mt={0}
                        align="center"
                        variant="subtitle2"
                        sx={{ fontStyle: "italic", fontWeight: "bold" }}
                        gutterBottom
                    >
                        AI-Based Entitlement Detector
                    </Typography>
                    <RouterLink to={"/"}>
                        <Box
                            display="flex"
                            m="auto"
                            component="img"
                            sx={{
                                content: {
                                    xs: `url(logo-light.png)`, //img src from xs up to md
                                    md: `url(logo-light.png)`, //img src from md and up
                                },
                            }}
                            alt="Entitlemeter Logo"
                            src={"logo-dark.png"}
                        />
                    </RouterLink>
                </Grid>
                <Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="flex-end"
                >
                    <Box sx={{ mb: 1 }}>
                        <TextField
                            size="small"
                            value={twitterId}
                            label={"Enter Twitter ID"}
                            placeholder={"ElonMusk"}
                            inputProps={{ maxLength: 15 }}
                            variant={"outlined"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        @
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton edge="end" color="primary">
                                        <TwitterIcon />
                                    </IconButton>
                                ),
                            }}
                            onChange={(event) => {
                                setTwitterId(event.currentTarget.value);
                            }}
                        />
                    </Box>
                </Grid>
                <Grid
                    container
                    item
                    xs={3}
                    justifyContent="space-evenly"
                    alignItems="stretch"
                    sx={{ mb: 1, pl: 10, pr: 5 }}
                >
                    <Grid container item xs={3}>
                        <IconButton
                            sx={{ mb: 4 }}
                            onClick={() => {
                                colorMode.toggle();
                            }}
                            color="inherit"
                        >
                            {theme.palette.mode === "dark" ? (
                                <Brightness7Icon />
                            ) : (
                                <Brightness4Icon />
                            )}
                        </IconButton>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                        xs={9}
                        sx={{ pt: 1 }}
                    >
                        <Box justifyContent="flex-top">
                            {sessionObj?.givenName && (
                                <div align={"center"}>
                                    <Button
                                        component={RouterLink}
                                        to={"/profile"}
                                    >
                                        <Avatar
                                            sx={{ m: 0, width: 32, height: 32 }}
                                            alt={
                                                sessionObj.givenName +
                                                " " +
                                                sessionObj.familyName
                                            }
                                            src={sessionObj.picture}
                                        />
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                                ml: 1,
                                                fontSize: 14,
                                                fontStyle: "italic",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Welcome, {sessionObj.givenName}
                                        </Typography>
                                    </Button>
                                    {sessionObj?.roles === "admin" && (
                                        <>
                                            <RouterLink
                                                to="/admin/"
                                                underline="always"
                                                color={"#333"}
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Admin
                                            </RouterLink>
                                            &nbsp;&nbsp;
                                        </>
                                    )}
                                    <LinkButton
                                        href="/"
                                        onClick={() => {
                                            logout();
                                        }}
                                        underline="always"
                                        color={"#333"}
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Logout
                                    </LinkButton>
                                </div>
                            )}
                            {!sessionObj?.givenName && (
                                <div align={"center"} id="signInDiv" />
                            )}
                        </Box>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={1}
                        >
                            <SocialIcon
                                target="_blank"
                                bgColor="#42a5f5"
                                style={{ height: 32, width: 32 }}
                                url="https://twitter.com/entitlemeter"
                            />
                            <SocialIcon
                                target="_blank"
                                bgColor="#42a5f5"
                                style={{ height: 32, width: 32 }}
                                url="https://www.linkedin.com/company/entitlemeter"
                            />
                            <SocialIcon
                                target="_blank"
                                bgColor="#42a5f5"
                                style={{ height: 32, width: 32 }}
                                url="https://medium.com/@entitlemeter"
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
