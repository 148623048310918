import { Box } from "@mui/material";
export const Terms = () => {
    return (
        <Box display="flex" justifyContent="center" minHeight="100vh">
            <Box maxWidth="80%">
                <h2>Entitlemeter Terms of Use</h2>
                <h4>
                    The Terms of Use below are a placeholder while Entitlemeter
                    is in Beta. The terms are non-binding and subject to change.
                </h4>
                <hr></hr>
                <p>
                    These Terms of Use (hereinafter referred to as the “Terms”)
                    govern your access to and use of the [entitlemeter] website
                    (hereinafter referred to as the “Website”). By accessing or
                    using the Website, you agree to be bound by these Terms and
                    all applicable laws and regulations. If you do not agree
                    with any of these Terms, you are prohibited from using or
                    accessing the Website. 1. Content The content on the
                    Website, including without limitation, the text, software,
                    scripts, graphics, photos, sounds, music, videos,
                    interactive features and the like (“Content”) and the
                    trademarks, service marks and logos contained therein
                    (“Marks”), are owned by [entitlemeter] and/or its licensors
                    and are subject to copyright and other intellectual property
                    rights under United States and foreign laws and
                    international conventions. Content on the Website is
                    provided to you “as is” for your information and personal
                    use only and may not be used, copied, reproduced,
                    distributed, transmitted, broadcast, displayed, sold,
                    licensed, or otherwise exploited for any other purposes
                    whatsoever without the prior written consent of
                    [entitlemeter]
                </p>
                <p>
                    Terms of Use for entitlemeter:
                    <ol>
                        <li>
                            Acceptance of the Terms of Use By using the
                            entitlemeter website (“entitlemeter”), you agree to
                            be bound by these Terms of Use (“Terms”). If you do
                            not agree to these terms of use, please do not use
                            entitlemeter.
                        </li>
                        <li>
                            Description of Service entitlemeter is a web-based
                            internet business providing services to its
                            registered users. The services provided include, but
                            are not limited to, the delivery of digital products
                            and services.
                        </li>
                        <li>
                            Account Registration In order to access and use
                            entitlemeter, you must register for an account. When
                            registering for an account, you must provide
                            accurate and complete information. You are solely
                            responsible for the security of your account and all
                            activities that occur under your account. You agree
                            to immediately notify entitlemeter of any
                            unauthorized use of your account or any other breach
                            of security. entitlemeter will not be liable for any
                            loss or damage arising from your failure to comply
                            with this section.
                        </li>
                        <li>
                            User Content You are solely responsible for any
                            content you submit to entitlemeter. You agree to not
                            submit any content that is abusive, obscene
                        </li>
                    </ol>
                </p>
            </Box>
        </Box>
    );
};
