import { useContext, useEffect, useState, } from "react";
import  Box  from '@mui/material/Box';
import { EntitlemeterContext } from "../App";
import { AdminTabs } from "./AdminTabs";

export const Searches = () => {

    // Manage State
    const {sessionObj} = useContext(EntitlemeterContext)
    const [searchData, setSearchData] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/admin_get_searches?sessionId=${sessionObj?.id}`)
            .then((data) => data.json())
            .then((data) => {
                setSearchData(data)
                console.log("in useEffect Data = " + data)
            })
    }, [sessionObj.id])

    return(
        <div>
            {(sessionObj?.roles==="admin")
                ?  <><AdminTabs />
                    <Box sx={{ height: 700, width: '100%', align:'center' }}>
                        {searchData}
                    </Box>
                    </>
                : <>Not Authorized</>
            }
        </div>
    )
};
