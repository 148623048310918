import { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Alert, Divider, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga";
import { Contact } from "./pages/Contact";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Home } from "./pages/Home";
import { ContextGraph } from "./MostByContext";
import { Results } from "./Results";
import { Profile } from "./pages/Profile";
import { Searches } from "./admin/Searches";
import { Servers } from "./admin/Servers";
import { Sessions } from "./admin/Sessions";
import { Votes } from "./admin/Votes";
import { Terms } from "./pages/Terms";
import { Privacy } from "./pages/Privacy";
import { Users } from "./admin/Users";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const EntitlemeterContext = createContext();
const google = (window.google = window.google ? window.google : {});
const TRACKING_ID = "UA-256759572-1";
ReactGA.initialize(TRACKING_ID);

// Define theme settings
const light = {
    palette: {
        mode: "light",
    },
};

const dark = {
    palette: {
        mode: "dark",
    },
};

function App() {
    const client = new QueryClient();
    console.log(process.env.NODE_ENV);
    // Manage State
    const [sessionObj, setSessionObj] = useState(
        process.env.NODE_ENV === 'development' 
        ? {
            id: -1,
            email: 'test@entitlemeter.com',
            givenName: 'Test',
            familyName: 'User',
            roles: 'admin'
        }
        : localStorage.getItem(`em-session-${process.env.NODE_ENV}`)
    );
    // The light theme is used by default
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // This function is triggered when the Switch component is toggled
    const changeTheme = () => {
        setIsDarkTheme(!isDarkTheme);
    };

    // Google Login Callback
    function handleCallbackResponse(response) {
        let userObject = jwt_decode(response.credential);

        if (userObject.email.length > 3) {
            let session = {
                email: userObject.email,
                givenName: userObject.given_name,
                familyName: userObject.family_name,
                picture: userObject.picture,
                colorMode: userObject.colorMode,
                roles: "",
            };
            //loginDate: new Date().toLocaleString(),
            let session_send = JSON.stringify(session);

            Axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/session_start`,
                session_send,
                {
                    headers: {
                        "content-type": "application/json",
                    },
                }
            ).then(
                (res) => {
                    let obj = {
                        id: res.data.id,
                        email: res.data.email,
                        givenName: res.data.givenName,
                        familyName: res.data.familyName,
                        picture: res.data.picture,
                        colorMode: res.data.colorMode,
                        loginDate: res.data.loginDate,
                        roles: res.data.roles,
                    };
                    console.log("received response: obj=", obj);
                    setSessionObj(obj);
                    localStorage.setItem(`em-session-${process.env.NODE_ENV}`, obj)
                },
                (error) => {
                    console.log("Error" + error);
                }
            );
        }
    }

    useEffect(() => {
        window.onload = function () {
            setTimeout(function () {
                google.accounts.id.initialize({
                    client_id:
                        "798630972753-g0332blui8obcsrqs9rkejij96ce0bfj.apps.googleusercontent.com",
                    callback: handleCallbackResponse,
                });

                google.accounts.id.renderButton(
                    document.getElementById("signInDiv"),
                    { theme: "outline", size: "medium" }
                );
            }, 1000);
        };
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [sessionObj]);

    return (
        <ThemeProvider
            theme={isDarkTheme ? createTheme(dark) : createTheme(light)}
        >
            <CssBaseline />
            <QueryClientProvider client={client}>
                <EntitlemeterContext.Provider
                    value={{ sessionObj, setSessionObj }}
                >
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>
                            Entitlemeter - AI-Based Entitlement Detector
                        </title>

                        <meta
                            name="title"
                            content="Entitlemeter - AI-Based Entitlement Detector"
                        />
                        <meta
                            name="description"
                            content="Internet's first-ever AI-Based Entitlement Detector, Check your entitlement score."
                        />
                        <meta
                            name="keywords"
                            content="entitlement, entitled, twitter, privilege, entitlement score, twitter stats"
                        />
                        <meta name="robots" content="index, follow" />
                        <meta
                            http-equiv="Content-Type"
                            content="text/html; charset=utf-8"
                        />
                        <meta name="language" content="English" />
                        <meta name="revisit-after" content="1 days" />
                        <meta name="author" content="@entitlemeter"></meta>

                        <meta property="og:type" content="website" />
                        <meta
                            property="og:description"
                            content="Internet's first-ever AI-Based Entitlement Detector, Check your entitlement score."
                        />

                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                        <meta name="twitter:site" content="entitlemeter" />
                        <meta
                            name="twitter:description"
                            content="Internet's first-ever AI-Based Entitlement Detector, Check your entitlement score."
                        />
                        <meta
                            property="og:image"
                            content="//entitlemeter.com/apple-touch-icon.png"
                        />
                        <link
                            rel="image_src"
                            href="https://entitlemeter.com/apple-touch-icon.png"
                        />
                        <meta
                            name="twitter:image"
                            content="https://entitlemeter.com/apple-touch-icon.png"
                        />
                        <meta
                            name="twitter:title"
                            content="Internet's first-ever AI-Based Entitlement Detector, Check your entitlement score. Twitter Stats | Twitter Score"
                        />
                        <meta name="twitter:creator" content="entitlemeter" />
                    </Helmet>
                    <Router>
                        <Paper>
                            <Header toggle={changeTheme} />
                            <Alert severity="info">
                                Entitlemeter.com is still under active
                                development.
                            </Alert>
                            <Divider />
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/admin" element={<Sessions />} />
                                <Route
                                    path="/searches"
                                    element={<Searches />}
                                />
                                <Route path="/servers" element={<Servers />} />
                                <Route
                                    path="/sessions"
                                    element={<Sessions />}
                                />
                                <Route path="/votes" element={<Votes context="entitle" />} />
                                <Route path="/results" element={<Results />} />
                                <Route
                                    path="/homegraph"
                                    element={<ContextGraph />}
                                />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/terms" element={<Terms />} />
                                <Route path="/users" element={<Users />} />
                                <Route
                                    path={"*"}
                                    element={<h1>404 Page not found</h1>}
                                />
                            </Routes>
                            <Footer />
                        </Paper>
                    </Router>
                </EntitlemeterContext.Provider>
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
