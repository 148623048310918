import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { Divider, Grid, Tab, Tabs } from "@mui/material";

import PieChartIcon from "@mui/icons-material/PieChart";
import HotTubIcon from "@mui/icons-material/HotTub";
import NoAdultContentIcon from "@mui/icons-material/NoAdultContent";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

// import { Categories } from "../Categories";
import { ContextGraph } from "../MostByContext";
import { ComingSoonCard } from "../components/ComingSoonCard";
import { Results } from "../Results";

export const Home = () => {
    const theme = useTheme();

    // Read params passed from header as ?twitterId=xxxxxx
    let [searchParams] = useSearchParams();

    // Build query params
    const [context, setContext] = useState(
        searchParams.get("context") || "entitle"
    );
    let twitterId = searchParams.get("twitterId");

    return (
        <Grid container spacing={3} mb={6}>
            <Grid item xs={12}>
                <Tabs
                    onChange={(_, newValue) => {
                        setContext(newValue);
                    }}
                    sx={{
                        backgroundColor:
                            theme.palette.mode === "dark" ? "#000" : "#FEFEFE",
                    }}
                    aria-label="entitlemeter navigation"
                    value={context}
                    centered
                >
                    <Tab
                        icon={<PieChartIcon />}
                        label="DASHBOARD"
                        value={"dashboard"}
                    />
                    <Tab
                        icon={<HotTubIcon />}
                        label="ENTITLEMENT"
                        value={"entitle"}
                    />
                    <Tab
                        icon={<NoAccountsIcon />}
                        label="BULLYING"
                        value={"bully"}
                    />
                    <Tab
                        icon={<NoAdultContentIcon />}
                        label="CURSE WORDS"
                        value={"curse"}
                    />
                </Tabs>

                <Divider />
            </Grid>
            {/* LEFT NAV - MOST ENTITLED */}
            {/* TRACKED ACCOUNTS - TODO: MAKE THIS A COMPONENT */}
            {/* <Grid item xs={2}>
                <Typography align="center">My Tracked Accounts</Typography>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="left">
                                    Twitter ID
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    Score
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                >
                                    <Avatar sx={{ width: 32, height: 32 }} />
                                </StyledTableCell>
                                <StyledTableCell align="left"></StyledTableCell>
                                <StyledTableCell align="right">
                                    <button>Add</button>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid> */}

            {/* <Grid xs={2} item mt={1} sx={{minWidth:'280px'}}>
                <MostEntitled />
            </Grid> */}
            {/* CONTENT AREA - GRAPHS */}
            <Grid container item xs={12} ml={5} mr={5}>
                {/*                 <Grid item xs={2}>
                    NAV
                </Grid> */}
                <Grid item xs={12}>
                    {twitterId > "" ? (
                        <Results twitterId={twitterId} />
                    ) : context === "bully" || context === "entitle" ? (
                        <ContextGraph context={context} />
                    ) : (
                        <ComingSoonCard />
                    )}
                </Grid>
            </Grid>

            {/* RIGHT NAV - CATEGORY SELECTION */}
            {/* <Grid xs={2} item mt={1} ml={"auto"} mr={"auto"}>
                <MostEntitled />
            </Grid> */}
        </Grid>
    );
};
