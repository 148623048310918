import {
  memo,
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef
}
  from "react";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridActionsCellItem,
  GridFooterContainer,
  GridPagination,
  GridToolbarExport
} from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import TwitterIcon from '@mui/icons-material/Twitter';
import { darken, lighten, styled } from '@mui/material/styles';
import { EntitlemeterContext } from "../App";
import { AdminTabs } from "./AdminTabs";

const Root = styled('div')(({ theme }) => ({
  border: `1px solid ${(theme.vars || theme).palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 26,
  borderRadius: 2,
}));

const Value = styled('div')({
  position: 'absolute',
  lineHeight: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const Bar = styled('div')({
  height: '100%',
  '&.low': {
    backgroundColor: '#f44336',
  },
  '&.medium': {
    backgroundColor: '#efbb5aa3',
  },
  '&.high': {
    backgroundColor: '#088208a3',
  },
});

const ProgressBar = memo(function ProgressBar(props) {
  const { value } = props;

  return (
    <Root>
      <Value>{`${value.toLocaleString()}`}</Value>
      <Bar
        className={clsx({
          low: value < 50,
          medium: value >= 50 && value <= 70,
          high: value > 70,
        })}
        style={{ maxWidth: `${value}` }}
      />
    </Root>
  );
});

ProgressBar.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderProgressBar(params) {
  if (params.value == null) {
    return '';
  }

  // If the aggregated value does not have the same unit as the other cell
  // Then we fall back to the default rendering based on `valueGetter` instead of rendering a progress bar.
  if (params.aggregation && !params.aggregation.hasCellUnit) {
    return null;
  }

  return <ProgressBar value={params.value} />;
}

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--true': {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--false': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.string,
};

function EnhancedTableToolbar(props) {
  const { userVotes, upVotes, downVotes, noneVotes, votes, filter, setVotes, setSelected, setLoading } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
      <Stack direction="row" spacing={2}>
        <Button variant={filter === 'total' ? "contained" : 'outlined'} onClick={() => {
          setSelected(filter === 'total' ? 'none' : 'total');
          if (filter === 'total') {
            setLoading(true);
          } else {
            setVotes(userVotes);
          }
        }}>{userVotes?.length} Total</Button>
        <Button variant={filter === 'up' ? "contained" : 'outlined'} onClick={() => {
          setSelected(filter === 'up' ? 'none' : 'up');
          if (filter === 'up') {
            setLoading(true);
          } else {
            setVotes([...userVotes].filter(vote => vote.vote === true));
          }
        }}>{upVotes?.length} Entitled</Button>
        <Button variant={filter === 'down' ? "contained" : 'outlined'} onClick={() => {
          setSelected(filter === 'down' ? 'none' : 'down');
          if (filter === 'down') {
            setLoading(true);
          } else {
            setVotes([...userVotes].filter(vote => vote.vote === false));
          }
        }}>{downVotes?.length} Not Entitled</Button>
        <Button variant={filter === 'novote' ? "contained" : 'outlined'} onClick={() => {
          setSelected(filter === 'novote' ? 'none' : 'novote');
          if (filter === 'novote') {
            setLoading(true);
          } else {
            setVotes([...votes].filter(vote => vote.vote === null));
          }
        }}>{noneVotes?.length} None</Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  totalVotes: PropTypes.array.isRequired,
  upVotes: PropTypes.array.isRequired,
  downVotes: PropTypes.array.isRequired,
  noneVotes: PropTypes.array.isRequired,
  votes: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
  setVotes: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  originalVotes: PropTypes.array.isRequired,
  setLoading: PropTypes.bool.isRequired,
  context: PropTypes.string.isRequired,
};

function CustomFooter() {
  return (
    <GridFooterContainer>
      <GridPagination />
      <GridToolbarExport />
    </GridFooterContainer>
  );
}

export const Votes = (context) => {
  const { sessionObj } = useContext(EntitlemeterContext)
  const [votes, setVotes] = useState([]);
  const [userVotes, setUserVotes] = useState([]);
  const [upVotes, setUpVotes] = useState([]);
  const [downVotes, setDownVotes] = useState([]);
  const [noneVotes, setNoneVotes] = useState([]);
  const [selected, setSelected] = useState('none');
  const [loading, setLoading] = useState(true);

  const addUpdateVote = useCallback(
    (val, vote) => () => {
      //console.log(val, vote);
      if (
        val !== vote.vote &&
        val === undefined &&
        vote.vote_id
      ) {
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/vote/${vote.vote_id}?session_id=${sessionObj?.id}`,
          {
            method: "DELETE",
          }
        ).then(() => {
          setVotes(prevVotes => {
            const index = prevVotes.findIndex(item => item.tweet_id === vote.tweet_id);
            if (index !== -1) {
              return [
                ...prevVotes.slice(0, index),
                { ...prevVotes[index], vote: undefined, vote_id: undefined },
                ...prevVotes.slice(index + 1)
              ];
            } else {
              return [...prevVotes];
            }
          });
          setUserVotes(prevVotes => prevVotes.filter(({ tweet_id }) => tweet_id !== vote.tweet_id));
          setUpVotes(prevVotes => prevVotes.filter(({ tweet_id }) => tweet_id !== vote.tweet_id));
          setDownVotes(prevVotes => prevVotes.filter(({ tweet_id }) => tweet_id !== vote.tweet_id));
          setNoneVotes(prevVotes => prevVotes.filter(({ tweet_id }) => tweet_id !== vote.tweet_id));
        });
      } else {
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/vote`,
          {
            method: "POST",
            headers: {
              "Content-Type":
                "application/json",
            },
            body: JSON.stringify({
              tweet_id: vote.tweet_id,
              context: context.context,
              session_id: sessionObj.id,
              value: val,
            }),
          }
        )
          .then((data) => data.json())
          .then((data) => {
            data.vote_id = data.id;
            if (!data.vote_id && vote.vote_id) {
              data.vote_id = vote.vote_id;
            }

            let originalVote;
            setVotes(prevVotes => {
              const index = prevVotes.findIndex(item => item.tweet_id === vote.tweet_id);
              if (index !== -1) {
                originalVote = { ...prevVotes[index], vote: val, vote_id: data.vote_id };
                return [
                  ...prevVotes.slice(0, index),
                  { ...prevVotes[index], vote: val, vote_id: data.vote_id },
                  ...prevVotes.slice(index + 1)
                ];
              } else {
                return [...prevVotes, data];
              }
            });

            setUserVotes(prevUserVotes => {
              const index = prevUserVotes.findIndex(item => item.tweet_id === vote.tweet_id);
              if (index !== -1) {
                return [
                  ...prevUserVotes.slice(0, index),
                  { ...prevUserVotes[index], vote: val, vote_id: data.vote_id },
                  ...prevUserVotes.slice(index + 1)
                ];
              } else {
                return [...prevUserVotes, originalVote];
              }
            });

            if (val === true) {
              setUpVotes(prevUserVotes => {
                const index = prevUserVotes.findIndex(item => item.tweet_id === vote.tweet_id);
                if (index !== -1) {
                  return [
                    ...prevUserVotes.slice(0, index),
                    { ...prevUserVotes[index], vote: val, vote_id: data.vote_id },
                    ...prevUserVotes.slice(index + 1)
                  ];
                } else {
                  return [...prevUserVotes, originalVote];
                }
              });
              setDownVotes(prevUserVotes => {
                return prevUserVotes.filter(item => String(item.tweet_id) !== String(vote.tweet_id));
              });
              setNoneVotes(prevUserVotes => {
                return prevUserVotes.filter(item => String(item.tweet_id) !== String(vote.tweet_id));
              });
            } else if (val === false) {
              setDownVotes(prevUserVotes => {
                const index = prevUserVotes.findIndex(item => item.tweet_id === vote.tweet_id && item.vote === true);
                if (index !== -1) {
                  return [
                    ...prevUserVotes.slice(0, index),
                    { ...prevUserVotes[index], vote: val, vote_id: data.vote_id },
                    ...prevUserVotes.slice(index + 1)
                  ];
                } else {
                  return [...prevUserVotes, originalVote];
                }
              });
              setUpVotes(prevUserVotes => {
                return prevUserVotes.filter(item => String(item.tweet_id) !== String(vote.tweet_id));
              });
              setNoneVotes(prevUserVotes => {
                return prevUserVotes.filter(item => String(item.tweet_id) !== String(vote.tweet_id));
              });
            } else {
              setNoneVotes(prevUserVotes => {
                const index = prevUserVotes.findIndex(item => item.tweet_id === vote.tweet_id && item.vote === null);
                if (index !== -1) {
                  return [
                    ...prevUserVotes.slice(0, index),
                    { ...prevUserVotes[index], vote: val, vote_id: data.vote_id },
                    ...prevUserVotes.slice(index + 1)
                  ];
                } else {
                  return [...prevUserVotes, originalVote];
                }
              });
              setUpVotes(prevUserVotes => {
                return prevUserVotes.filter(item => String(item.tweet_id) !== String(vote.tweet_id));
              });
              setDownVotes(prevUserVotes => {
                return prevUserVotes.filter(item => String(item.tweet_id) !== String(vote.tweet_id));
              });
            }
          });
      }
    },
    [context, sessionObj],
  );

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={params.row.vote === true ? (
            <ThumbUpAltIcon />
          ) : (
            <ThumbUpOffAltIcon />
          )}
          label="Set Entitled"
          onClick={
            addUpdateVote(params.row.vote === true &&
              params.row.vote_id
              ? undefined
              : true, params.row)
          }
        />,
        <GridActionsCellItem
          icon={params.row.vote === false ? (
            <ThumbDownAltIcon />
          ) : (
            <ThumbDownOffAltIcon />
          )}
          label="Set Entitled"
          onClick={
            addUpdateVote(params.row.vote === false &&
              params.row.vote_id
              ? undefined
              : false, params.row)
          }
        />,
      ],
    },
    {
      field: "tweet_id",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        const link = `https://twitter.com/${params.row.twitter_id}/status/${params.row.tweet_id}`;
        return (
          <Link href={link} target="_blank">
            <TwitterIcon />
          </Link>
        )
      }
    },
    {
      field: 'text',
      headerName: 'Tweet',
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'score',
      headerName: 'Score',
      type: 'number',
      width: 110,
      renderCell: renderProgressBar,
    },
    {
      field: 'twitter_id',
      headerName: 'User',
      width: 180,
      renderCell: (params) => {
        const link = `https://twitter.com/${params.row.twitter_id}`;
        return (
          <Link href={link} target="_blank">@{params.row.twitter_id}</Link>
        )
      }
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'dateTime',
      width: 180,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'vote',
      headerName: 'Vote',
      type: 'string',
      width: 110,
      valueGetter: value => value.value === true ? 'Entitled' :
        value.value === false ? 'Not Entitled' : 'None',
    }
  ];

  useEffect(() => {
    if (sessionObj?.id) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/tweet_votes/${context.context}?session_id=${sessionObj?.id}`)
        //fetch('./votes.json')
        .then((data) => data.json())
        .then((data) => {
          setVotes(data);
          setNoneVotes(data.filter(v => v.vote === null));
          fetch(`${process.env.REACT_APP_API_BASE_URL}/votes/${context.context}?session_id=${sessionObj?.id}`)
            .then((data) => data.json())
            .then((data) => {
              setUserVotes(data);
              setUpVotes(data.filter(v => v.vote === true));
              setDownVotes(data.filter(v => v.vote === false));
              setLoading(false);
            });
        });
    }
  }, [context, sessionObj, loading]);

  return (
    <div>
      {(sessionObj?.roles === "admin")
        ? <><AdminTabs />
          <Box sx={{ height: 500, width: '100%', align: 'center' }}>
            <EnhancedTableToolbar
              userVotes={userVotes}
              upVotes={upVotes}
              downVotes={downVotes}
              noneVotes={noneVotes}
              votes={votes}
              filter={selected}
              setVotes={setVotes}
              setSelected={setSelected}
              setLoading={setLoading}
              context={context?.context || 'entitle'}
            />
            <StyledDataGrid
              loading={loading}
              rows={votes}
              columns={columns}
              getRowId={(row) => row.tweet_id}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'score', sort: 'desc' }],
                },
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              getRowClassName={(params) => `super-app-theme--${params.row.vote}`}
              checkboxSelection
              disableRowSelectionOnClick
              rowsPerPageOptions={[5, 10, 20]}
              components={{
                Footer: CustomFooter,
              }}
            />
          </Box>
        </>
        : <>Not Authorized</>
      }
    </div>
  )
};